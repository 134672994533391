import * as React from "react";
import Layout from "../components/Layout";
import "./tc.sass";

const PricePage = () => {
  return (
    <Layout>
      <div id="tc-container">
        <h2>Conditions générales d'utilisation</h2>
        <p>
          Conditions générales d'utilisation En vigueur au 10/10/2020 <br />{" "}
          <br /> Les présentes conditions générales d'utilisation (dites « CGU
          ») ont pour objet l'encadrement juridique des modalités de mise à
          disposition du site et des services de weely.ch et de définir les
          conditions d’accès et d’utilisation des services par « l'Utilisateur
          ». Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
          <br /> <br />
          ARTICLE 1 : Les mentions légales L’édition et la direction de la
          publication du site weely.ch est assurée par weely.ch, domicilié 4
          Chemin des Vignes 1295 Tannay. Numéro de téléphone:  0794485377
          Adresse e-mail: contact@weely.ch. Les hébergeurs du site weely.ch
          sont: • Wix, hébergeur du site weely.ch (https://www.wix.com/) •
          Netlify, hébergeur de l’application web app.weely.ch
          (https://www.netlify.com/) • Heroku et Amazon Web Services, hébergeur
          du serveur de l’application app.weely.ch (https://.heroku.com) • Mlab
          et Amazon Web Services, hébergeur de la base de données de
          l’application app.weely.ch
          (https://mlab.com/, https://aws.amazon.com/) <br /> <br />
          ARTICLE 2 : Accès au site Le site weely.ch permet à l'Utilisateur un
          accès gratuit aux services suivants : • Mise a disposition d'un roue
          digitale personnalisable Le site internet propose les services
          suivants : • service de fidélisation • service d'acquisition client Le
          site est accessible gratuitement en tout lieu à tout Utilisateur ayant
          un accès à Internet. Tous les frais supportés par l'Utilisateur pour
          accéder au service (matériel informatique, logiciels, connexion
          Internet, etc.) sont à sa charge. <br /> <br />
          ARTICLE 3 : Collecte des données Le site assure à l'Utilisateur une
          collecte et un traitement d'informations personnelles dans le respect
          de la vie privée conformément à la législation suisse en matière de
          protection des données. En vertu de la loi Suisse sur les collectes de
          données, l'Utilisateur dispose d'un droit d'accès, de rectification,
          de suppression et d'opposition de ses données personnelles. Ceci est
          possible en notifiant weely.ch par e-mail. ​ <br /> <br />
          ARTICLE 4 : Propriété intellectuelle Les marques, logos, signes ainsi
          que tous les contenus du site (textes, images, son…) font l'objet
          d'une protection par le Code de la propriété intellectuelle et plus
          particulièrement par le droit d'auteur. L'Utilisateur doit solliciter
          l'autorisation préalable du site pour toute reproduction, publication,
          copie des différents contenus. Il s'engage à une utilisation des
          contenus du site dans un cadre strictement privé, toute utilisation à
          des fins commerciales et publicitaires est strictement interdite.
          Toute représentation totale ou partielle de ce site par quelque
          procédé que ce soit, sans l’autorisation expresse de l’exploitant du
          site Internet constituerait une contrefaçon sanctionnée par la
          législation Suisse de la propriété intellectuelle. Il est rappelé
          conformément à la législation suisse de la  propriété intellectuelle
          que l’Utilisateur qui reproduit, copie ou publie le contenu protégé
          doit citer l’auteur et sa source. <br /> <br />
          ARTICLE 5 : Responsabilité Les sources des informations diffusées sur
          le site weely.ch sont réputées fiables mais le site ne garantit pas
          qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les
          informations communiquées sont présentées à titre indicatif et général
          sans valeur contractuelle. Malgré des mises à jour régulières, le site
          weely.ch ne peut être tenu responsable de la modification des
          dispositions administratives et juridiques survenant après la
          publication. De même, le site ne peut être tenu responsable de
          l’utilisation et de l’interprétation de l’information contenue dans ce
          site. Le site weely.ch ne peut être tenu pour responsable d’éventuels
          virus qui pourraient infecter l’ordinateur ou 2 tout matériel
          informatique de l’Internaute, suite à une utilisation, à l’accès, ou
          au téléchargement provenant de ce site. La responsabilité du site ne
          peut être engagée en cas de force majeure ou du fait imprévisible et
          insurmontable d'un tiers. ou L’utilisation du Site se fait à vos
          propres risques. Le Site vous est proposé ‘en l’état’ et ‘tel qu’il
          est disponible’. Par conséquent, weely n’offre aucune garantie. Nous
          ne garantissons en particulier pas que (i) le Site et les
          produits/services offerts sur celui-ci sont conformes à vos exigences;
          (ii) le Site et son contenu sont exhaustifs, exacts, précis, fiables,
          actualisés et ne transgressent pas les droits d’un tiers; (iii)
          l’accès au Site ne connaîtra pas d’interruption ou d’erreur, ou est
          garanti sans virus; (iv) les défauts seront corrigés; (v) le Site est
          sécurisé; ou (vi) tout conseil ou opinion provenant de weely par
          l’intermédiaire de ce Site est exact et fiable. Toute information
          présente sur le Site est sujette à modification ou suppression sans
          préavis. ou encore DANS LA MESURE PERMISE PAR LA LOI, TOUTE
          RESPONSABILITÉ DE weely, SES ORGANES, SES AUXILIAIRES ET SES
          REPRÉSENTANTS, POUVANT RÉSULTER DE VOTRE ACCÈS AU - OU VOTRE
          UTILISATION DU - SITE, EST EXCLUE. EN TANT QUE CONDITION PRÉALABLE À
          VOTRE UTILISATION DU SITE, VOUS VOUS ENGAGEZ À INDEMNISER weely, AINSI
          QUE SES ORGANES, SES AUXILIAIRES ET SES REPRÉSENTANTS DE TOUT DOMMAGE
          (Y COMPRIS LES FRAIS DE JUSTICE ET D'AVOCAT(S)) QUI POURRAIT RÉSULTER
          DE VOTRE ACCÈS AU - OU VOTRE UTILISATION DU - SITE. <br /> <br />
          ARTICLE 6 : Liens vers d’autres sites Des liens sur le Site peuvent
          vous diriger hors de nos réseaux et de nos systèmes, notamment vers
          les sites Internet de nos partenaires. Weely n’accepte aucune
          responsabilité concernant le contenu, l’exactitude ou le
          fonctionnement de ces sites tiers. Ces liens sont proposés de bonne
          foi et weely ne peut être tenue pour responsable du contenu des sites
          tiers vers lesquels un lien est proposé. L’inclusion d’un lien vers
          d’autres sites Internet n’implique pas l’approbation de weely. Nous
          vous recommandons de lire attentivement les mentions légales et l’avis
          concernant la protection des données personnelles de tous les autres
          sites que vous visitez.
          <br /> <br />
          ARTICLE 7 : Cookies L’Utilisateur est informé que lors de ses visites
          sur le site, un cookie peut s’installer automatiquement sur son
          logiciel de navigation. Les cookies sont de petits fichiers stockés
          temporairement sur le disque dur de l’ordinateur de l’Utilisateur par
          votre navigateur et qui sont nécessaires à l’utilisation du site
          weely.ch. Les cookies ne contiennent pas d’information personnelle et
          ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie
          contient un identifiant unique, généré aléatoirement et donc anonyme.
          Certains cookies expirent à la fin de la visite de l’Utilisateur,
          d’autres restent. L’information contenue dans les cookies est utilisée
          pour améliorer le site weely.ch. En naviguant sur le site,
          L’Utilisateur les accepte. L’Utilisateur doit toutefois donner son
          consentement quant à l’utilisation de certains cookies. A défaut
          d’acceptation, l’Utilisateur est informé que certaines fonctionnalités
          ou pages risquent de lui être refusées. L’Utilisateur pourra
          désactiver ces cookies par l’intermédiaire des paramètres figurant au
          sein de son logiciel de navigation. <br /> <br />
          ARTICLE 9: Traitement des communications à weely - Sécurité des
          e-mails Les communications que vous adressez à weely par Internet ne
          seront pas considérées comme confidentielles. Lorsque vous communiquez
          avec nous par e-mails, vous devez tenir compte du fait que la
          protection des messages e-mail transmis par Internet n'est pas
          garantie. Par conséquent, en envoyant par e-mails des messages
          importants, non encodés, vous acceptez les risques de cette
          incertitude <br /> <br />
          ARTICLE 10: Activités interdites Weely se réserve le droit d’interdire
          (p.ex. en supprimant le contenu concerné ou en limitant/interdisant
          l'accès à tout ou partie du Site), sans préavis, tout acte que nous
          pourrions juger inapproprié et/ou que nous pourrions estimer être
          contraire à la loi ou à la bienséance, y compris (entre autres) toutes
          tentatives de tricherie et abus susceptibles d’être préjudiciables aux
          systèmes de sécurité de weely (ou de parties tierces). Sauf accord
          écrit préalable de weely, l'utilisateur dont l'accès au Site a été
          supprimé n'est plus autorisé à s'inscrire sur le Site, que cela soit
          sous son nom ou sous un autre nom. <br /> <br />
          ARTICLE 11: Nullité partielle Toute disposition des Conditions
          d'Utilisation qui serait, en tout ou en partie, en contradiction avec
          le droit impératif suisse sera dissociable et toute nullité, totale ou
          partielle, d'une telle clause n'affectera pas la validité du reste de
          la clause en question, ni des autres clauses des Conditions
          d'Utilisation. Si une disposition des Conditions d'Utilisation
          s'avérait sans effet en tout ou en partie, elle devra être remplacée
          par une disposition produisant des effets économiques et juridiques
          aussi proches que possible de ceux de la disposition invalidée.
          <br /> <br />
          ARTICLE 12: Divers Les Conditions d'Utilisation constituent l’entier
          de l’accord entre weely et vous pour ce qui concerne l’accès et
          l’utilisation du Site et du Contenu. Le fait pour weely de tolérer des
          manquements par vous-même à l’une de vos obligations prévues par les
          Conditions d'Utilisation, ou de ne pas exiger l’application d’un droit
          qui est reconnu à weely par les Conditions d'Utilisation ou de par la
          loi, ne peut être interprété comme une renonciation de sa part à s’en
          prévaloir. <br /> <br />
          ARTICLE 13 : Droit applicable et juridiction compétente La législation
          suisse s'applique au présent contrat. En cas d'absence de résolution
          amiable d'un litige né entre les parties, les tribunaux suisses seront
          seuls compétents pour en connaître. Pour toute question relative à
          l’application des présentes CGU, vous pouvez joindre l’éditeur aux
          coordonnées inscrites à l’ARTICLE 1. Idées à peut-être intégrer: -La
          participation aux Jeux est régie par le règlement du jeu, qui fait
          partie intégrante des Conditions d'Utilisation. Toutes les
          informations et la documentation contenues sur le Site (ainsi que sur
          les pages que nous animons sur les réseaux sociaux) sont uniquement
          présentées à titre indicatif et dans le but de promouvoir nos produits
          et services. Les informations et la documentation présentées ne
          constituent en aucun cas une offre de produits et/ou de services de
          notre part, ni de la part de nos partenaires ou de tiers, et ne
          peuvent être considérées comme conseils ou instructions sur
          l’utilisation de nos produits et services. Nous mettons en œuvre tous
          les efforts raisonnables pour nous assurer que les informations
          figurant sur le Site (ainsi que sur les pages que nous animons sur les
          réseaux sociaux) sont exactes et complètes. Nous rejetons cependant
          toute responsabilité si tel ne devait pas être le cas. Si vous vous
          fiez à ces informations, ce sera à vos risques et périls. Vous
          convenez qu’il est de votre responsabilité de vous tenir au courant de
          toute modification apportée au contenu du Site. Toute responsabilité
          de weely est exclue à cet égard. Il appartient au Client de s'informer
          auprès des autorités compétentes quant aux éventuelles restrictions
          s'agissant de l'utilisation des produits qu'il souhaite commander.
        </p>
      </div>
    </Layout>
  );
};

export default PricePage;
